import * as React from "react"
import { Link, graphql } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"


const Partnerships = ({data}) => {
  const pageContent = data.allWpPage.nodes[0];
  const pagePosts = data.allWpTechnicalPartnership.nodes;

  return(
  <Layout>
    <Seo title={pageContent?.seo.title} image="https://dev.alkye.com/wp-content/uploads/2021/12/Sonia-1-1-scaled-1.webp" description={pageContent?.seo.metaDesc} focuskw={pageContent?.seo.focuskw} />
    
    <div className="page-intro page-inner">
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-xl-9 col-xxl-10" data-aos="fade-up">
            <h1 className="title mb-3 mb-md-4 pb-xl-2" dangerouslySetInnerHTML={{__html: pageContent?.title}} />
            
            <div dangerouslySetInnerHTML={{__html: pageContent?.content}} />
          </div>
        </div>
      </div>
    </div>

    {pagePosts && pagePosts?.map((partner, index) => (
      <div className={`partnerships-block ps-xxl-0 ${index % 2 && 'bg-primary'} ps-md-5 py-120`} key={index}>
          <div className="container mb-md-5 mb-sm-4">
              <div className={`partnership-circle d-sm-flex ${index % 2 && ' ms-auto me-md-0 me-auto me-xxl-0 me-md-5'}`}>
                  <div className="partnership-circle-content m-auto">
                    <h2 dangerouslySetInnerHTML={{__html: partner.title}} />
                    <div className="mb-1" dangerouslySetInnerHTML={{__html: partner.content}} />

                    <div className="text-end">
                      <Link aria-label={partner.slug} to={"/partnerships/" + partner.slug} className="button-circle  btn p-0 border-0">
                        {/* <StaticImage
                          src="/learn-more.svg"
                          quality={100}
                          formats={["auto", "webp", "avif"]}
                          className="img-fluid" alt="project"
                        /> */}
                        <img loading="lazy" width="150" height="45" src="/learn-more.svg" alt="project" className="img-fluid" />
                      </Link>
                    </div>
                  </div>
                  <div className="partnership-circle-logos d-flex flex-wrap align-items-center mt-sm-0 mt-4 justify-content-between">
                    {partner.technicalPartnership.logos && partner.technicalPartnership.logos.map((logo, index) => (
                      <div className={`partnership-circle-logo partnership-circle-logo-${index + 1}`} key={index}>
                        <img loading="lazy" width="150" height="45" src={logo.logo.mediaItemUrl} alt={logo.logo.altText} className="img-fluid gatsby-image-wrapper" />
                      </div>
                    ))}
                    </div>
              </div>
          </div>
      </div>
    ))}   
  
    </Layout>
  )
}

export default Partnerships

export const query = graphql`{
  allWpPage(filter: {slug: {eq: "partnerships"}}) {
      nodes {
        title
        content
        seo {
          title
          metaDesc
          focuskw
        }
      }
  }
  allWpTechnicalPartnership(sort: {fields: date, order: DESC}) {
    nodes {
      title
      slug
      content
      technicalPartnership {
        logos {
          logo {
            altText
            mediaItemUrl
          }
        }
      }
    }
  }
}`